// Service Tabs
.services-tabs {
  .btn-info {
    background-color: rgba(222,222,222,.5) !important;
    color: #444;
  }
  .btn.btn-icon {
    height: 5.375rem;
    width: 9.375rem;
    @media screen and (max-width: 768px) {
      width: 6.375rem;
    }
  }
  .active {
    .btn {
      background-color: $main-color;
      color: #ffffff
    }
    h3 {
      color: $main-color;
    }
  }
  .service-icon {
    .btn.btn-icon, .navbar .navbar-nav > a.btn.btn-icon {
      font-size: 1.975rem;
      i.fas {
        left: 46%
      }
      @media screen and (max-width: 768px) {
        i.fas {
          left: 44%
        };
      }
    }
  }
}

// Header Styles
.services-header {
  .content-left {
    position: absolute;
    bottom: 30px;
    left: 5%;
    z-index: 2;
    text-align: left;
    color: #FFFFFF;
    padding: 0 15px;
    width: 90%;
    max-width: 750px;
  }
  
  h1 {
    font-weight: 300;
  }

  p {
    font-weight: 300;
    font-size: 1.6em;
    font-family: "bebas-neue-pro", sans-serif;
    letter-spacing: 1px;
    @media screen and (max-width: 768px) {
      font-size: 1.2em
    }
  }

  @media screen and (max-width: 768px) {
    font-size: 12px
  }
}

.collections {
  li {
    font-size: 1em;
  }

  h3 {
    margin:0;
  }
}

.cfs-logo {
  width: 80px;
}