.jobs-header {
  .content-left {
    position: absolute;
    bottom: 5%;
    left: 5%;
    z-index: 2;
    text-align: left;
    color: #FFFFFF;
    padding: 0 15px;
    max-width: 45%;
    @media screen and (max-width: 768px) {
      max-width: 100%;
      bottom: unset;
      top: 20%
    }
  }
  
  .jobs-title {
    font-weight: 300;
  }

  .image-overlay {
    width: 70%;
    top: -20%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
}

.jobs-wrapper {
  .btn-link {
    color: #158FBF;
    font-size: 1em
  }
}
