.about-us-page {

    .title {
        text-align: center;
        margin-top: 30px;
        font-weight: 400;
    }

    @media screen and (min-width: 576px){
        .page-header.page-header-small{
            min-height: 180px;
            max-height: 180px;
            background-color: #222559 !important;
        }
    }

    @media screen and (max-width: 577px){
        .page-header.page-header-small{
            min-height: 150px;
            max-height: 150px;
            background-color: #222559 !important;
        }
    }


    @media screen and (min-width: 576px){
        .page-header .container i {
            margin-top: 35px;
            font-size: 30px;
        }
    }

    @media screen and (max-width: 577px){
        .page-header .container {
            justify-content: center;
        }
        .page-header .container i {
            display: none;
        }
    }

    .mission-section .image-container {
        height: 70vh;
        position: relative;
        background-position: bottom 20% center;
        background-size: cover;
        box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
        border-radius: .25rem;
    }

    .who-we-are {
        background: #222559;
        color: #FFF;
    }

    @media screen and (max-width: 768px){
        .contact-us-section {
            text-align: center;
        }
        .contact-us-section button {
            margin-left: 10px;
        }
    }
}
