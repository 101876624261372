// Home Hero Image Styles

.home-header {
  .content-left {
    position: absolute;
    bottom: 0;
    left: 5%;
    z-index: 2;
    text-align: left;
    color: #FFFFFF;
    padding: 0 15px;
    width: 100%;
    max-width: 880px;
  }
  
  .home-title {
    font-weight: 300;
  }
}

.section-about-us {
  .container {
    margin-top: 80px;
  }
}


// Home Services Section Styles
.section-services {
  background-color: rgba(34,37,89,.2);
}

.services-wrapper {
  .service-icon {
    padding: 15px;
    flex-basis: 25%;
    flex-grow: 1;
    flex-shrink: 1;
    :hover {
      opacity: .8;
    }
  }
  
  .btn.btn-icon {
    height: 3.375rem;
    width: 3.375rem;
    font-size: 1.25rem;
  }
}

.alert button.close {
  margin-top: 10px;
  @media screen and (max-width: 580px) {
    right: 0;
    top: 8%;
  }
}

.alert {
  z-index:10000; 
}