@import "global.scss";


.main-nav {
  background-color: #f9f9f9;
  
  ul {
    @media screen and (max-width: 991px) {
      padding-left: 30px;
    }
  }

  .resources-header {
    color: #FFFFFF;
    padding-top: 1.4em;
    font-weight: 400;
    font-size: 1.2em;
    font-family: "bebas-neue-pro", sans-serif;
    @media screen and (max-width: 768px) {
      font-size: 1.6em;
    }
  }

  .navbar-toggler-bar {
    background: $main-color;
  }

  .navbar-nav {
    margin-right: 60px
  }

  .navbar-brand {
    margin-left: 60px;
    @media screen and (max-width: 991px) {
      margin-left: 0px;
    }
  }
  

  .resources-dropdown {
    text-transform: uppercase;
    font-family: "bebas-neue-pro", sans-serif;
    font-size: 1.25em;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 1.625rem;
    align-self: center;
    a {
      color: $main-color;
    }
    @media screen and (max-width: 991px) {
      display: none;
    }
  }

  .dropdown-item {
    font-size: 1.1em !important;
    font-weight: 600;
    text-transform: none;
  }

  ul li a.nav-link {
    font-family: "bebas-neue-pro", sans-serif;
    color: #222559 !important;
    font-size: 1.25em !important;
    line-height: 1.625rem;
    font-weight: 600;
    letter-spacing: 1px;
    @media screen and (max-width:991px){
      font-weight: 400;
    }
  }

  #resourceDropdown {
    padding: 0.5rem 0.7rem;
  }

  .navbar-nav .nav-item .nav-link:not(.btn):hover {
    background-color: rgba(34, 37, 39, 0.2);
    border-radius: 0.1875rem;
  }

  .dropdown-menu .dropdown-item:hover, .dropdown-menu .dropdown-item:focus, .bootstrap-select .dropdown-menu.inner li a:hover, .bootstrap-select .dropdown-menu.inner li a:focus {
    background-color: rgba(34, 37, 39, 0.2);
  }
}

.sidebar-collapse .navbar-collapse:before {
  padding-left: 15px;
  font-size: 1em;
  @media screen and (max-width: 991px) {
    background: #2c2c2c;
  }
}













