// Import/Variable for Header Font
@import url("https://use.typekit.net/rzh8hlp.css");
$sans-serif-headers:          'bebas-neue-pro', sans-serif;


// Color Palette
$main-color: #222559 !important;

.wrapper {
  padding-top: 104px;
  overflow: hidden;
}


// Overrides for Font Styles
$font-paragraph2:                  1em          !default; // ~ 14px
$font-paragraph-sm:               .8em;

h1,h2,h3,h4,h5,h6 {
  font-family: $sans-serif-headers;
  letter-spacing: 1px;
}

.description, .card-description, .footer-big p {
  color: $light-black;
}

.title {
  font-weight: normal;
}

.p {
  font-weight: 400;
}

// Button Color
.btn-info {
  background-color: $main-color;
}

.btn-info.disabled:hover {
  opacity: .65;
  cursor: auto;
  box-shadow: none;
}

// Footer Styles
.footer {
  font-family: $sans-serif-headers;
  font-size: 18px;
  letter-spacing: 1px;
  padding-left: 30px;
  padding-right: 30px;

  .copyright {
    float: none;
    padding-top: 30px;
  }
  a {
    font-weight: 500;
    font-size: 1.2em;
  }
  p {
    font-size: 1.2em;
    margin-bottom: 0;
    font-weight: 500;
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
  .nav-links li a {
    font-size: 1.2em;
    text-transform: none;
    padding: 0;
    @media screen and (max-width: 768px) {
      padding: 0
    }
  }

  ul li {
    display: flex;
    a {
      font-size: .8em;
    }
    :hover {
      text-decoration: underline !important;
    }
    @media screen and (max-width: 768px) {
      justify-content: center;
    }
  }
  .company-info {
    align-content: flex-end;
    text-align: right;
    @media screen and (max-width: 768px) {
      align-items: center;
      text-align: center;
      padding-top: 40px;
    }
    p, a {
      font-size: 1em;
    }
    a.linkedin {
      font-size: 1.2em
    }
  }

  .payment-methods {
    display: flex;
    flex-direction: column;
    p {
      font-size: 1em;
    }
    @media screen and (max-width: 768px) {
      align-items: center;
    }
  }

  .freight-force-logo {
    @media screen and (max-width: 768px) {
      width: 20%;
    }
  }

  figure {
    padding-bottom: 20px;
  }

}

.paypal-logo, .melio-logo, .paycargo-logo {
  width: 72px;
  margin-top: 8px;
}


.profile-page .gallery {
  margin-top: 0;
}


// Form Style Overrides
.form-control {
  border: 1px solid $light-black;
  .has-success &{
    border-color: $light-black;
  }
}

.input-group-text {
  border: 1px solid $light-black;
}

.input-group, .form-group{
  .input-group-prepend{
    .input-group-text {
      border-left: 1px solid $light-black
    }
  }
}

.input-group-append .input-group-text {
  border-right: 1px solid $light-black;
}

textarea.form-control {
  border-bottom: 1px solid $light-black;
}

textarea.text-area.is-invalid.form-control {
  border-color: #dc3545 !important;
}

.input-group-text {
  border-radius: 4px;
}
.form-control {
  border-radius: 4px;
}
textarea.form-control {
  border: 1px solid #444 !important;
  border-radius: 4px;
}
.form-control:focus{
  border-color: #158FBF;
}

.input-group.input-group-focus .input-group-prepend .input-group-text, .form-group.input-group-focus .input-group-prepend .input-group-text{
  border-color: #158FBF;
}

.modal .modal-header .close {
  color: #000000
}

.rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle {
  color: #158FBF
}

textarea.form-control:focus, textarea.form-control:active {
  border: 1px solid #158FBF !important;
}

label:not(.form-check-label) {
  margin-top: 20px;
}

// .input-group, .form-group {
//   margin-bottom: 40px;
// }

label {
  font-size: .9em;
}


// Carousel Styles
.carousel-indicators li {
  background-color: #fff
}

.blockquote {
  border: none;
  width: 50%;
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: flex;
  justify-content: center;
}

.custom-tag {
  max-width: 100%;
  padding: 5% 0;
  background: rgba(0,0,0,.8);
  color: #fff;
  font-weight:600
}